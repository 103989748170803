<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Kurikulum
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <b-row>
          <b-col md="8">
            <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, }"
                    >
                      <b-form-input
                        v-model="name"
                        placeholder="Masukkan Nama"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Skill Role"
                    label-class="font-medium text-7 text-black"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Skill Role`"
                      rules="required"
                    >
                      <v-select
                        v-model="skillId"
                        class="w-100"
                        placeholder="Pilih Skill Role"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkills"
                        :reduce="skill => skill.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Level"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Level"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="level"
                        class="mt-50"
                        :options="levelOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Passing Grade"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Passing Grade"
                      rules="required|numeric|max_value:100"
                    >
                      <b-form-input
                        v-model="passingGrade"
                        placeholder="Contoh: 80"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Attachement"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <div class="d-flex gap-10">
                      <div class="w-100">
                        <validation-provider
                          #default="{ errors }"
                          name="Attachement"
                        >
                          <div class="d-flex attachement">
                            <input
                              id="custom-link"
                              v-model="attachementLink"
                              type="text"
                              name="custom-link"
                              placeholder="Isikan link attachement atau pilih file"
                              :disabled="fileSelected ? true : false"
                            >
                            <div
                              id="custom-file"
                              class="d-flex relative"
                            >
                              <input
                                ref="inputFile"
                                class="absolute h-100 opacity-0"
                                type="file"
                                name="attachement"
                                @change="uploadFile($event)"
                              >
                              <button class="w-100 text-danger">
                                Pilih File
                              </button>
                            </div>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div v-if="fileSelected || initialFile" class="d-flex gap-[10px]">
                        <BButton
                          variant="outline-info"
                          class="ml-auto p-0 w-[40px] d-flex items-center justify-center"
                          target="_blank"
                          :href="fileSelected ? fileUrl(fileSelected) : attachementLink"
                        >
                          <img
                            src="https://storage.googleapis.com/komerce/assets/icons/eye-blue.svg"
                            alt="Komerce"
                            class="h-[20px]"
                          >
                        </BButton>
                        <BButton
                          variant="outline-danger"
                          class="ml-auto p-0 w-[40px] d-flex items-center justify-center"
                          @click="clearFile()"
                        >
                          <img
                            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                            alt="Komerce"
                            class="h-[20px]"
                          >
                        </BButton>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Simpan
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  numeric,
  ext,
  max_value,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      numeric,
      max_value,
      ext,
      capitalize,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      detailCurriculums: {},

      name: '',
      level: '',
      listSkills: [],
      skillId: null,
      fileSelected: null,
      attachementLink: null,
      passingGrade: '',
      initialFile: null,

      levelOptions: [
        { text: 'Basic', value: 'basic' },
        { text: 'Spesifik', value: 'specific' },
        { text: 'Pematangan', value: 'mature' },
      ],
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListSkills()
    this.getDetailCurriculums()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('skill_id', Number.isInteger(this.skillId) ? this.skillId : this.detailCurriculums.skill_id)
      formData.append('level', this.level)
      formData.append('passing_grade', this.passingGrade)
      formData.append('attachment_link', this.fileSelected ? '' : this.attachementLink)

      if (this.fileSelected) formData.append('attachment', this.fileSelected)

      const url = `v1/curriculums/${this.id}/update`
      await komtimAxiosIns
        .put(url, formData)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'curriculum' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getDetailCurriculums() {
      this.offset = 0
      const url = `v1/curriculums/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailCurriculums = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    toQuiz(params) {
      this.idTalent = params
      this.currentStep = 2
      this.buttonBackActive = false
    },
    handleBackFromQuiz() {
      this.currentStep = 1
      this.buttonBackActive = true
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
    alertSuccess(text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Success',
            text,
            variant: 'success',
            icon: 'CheckIcon',
          },
        },
        { timeout: 2500 },
      )
    },
    loadForm() {
      this.name = this.detailCurriculums.name
      this.skillId = this.detailCurriculums.skill_name
      this.level = this.detailCurriculums.level
      this.passingGrade = this.detailCurriculums.passing_grade
      this.initialFile = this.detailCurriculums.attachment
      this.attachementLink = this.detailCurriculums.attachment
    },
    uploadFile(e) {
      const fileSelected = e.target.value
      const fileExtension = fileSelected.substr((fileSelected.lastIndexOf('.') + 1))
      if (fileExtension !== 'pdf' && fileExtension !== 'xls' && fileExtension !== 'xlsx') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi .pdf, .xls, .xlsx')
        this.fileSelected = ''
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.fileSelected = e.target.files[0]
        this.attachementLink = fileSelected.split('\\').pop()
      }
    },
    fileUrl: file => URL.createObjectURL(file),
    clearFile() {
      this.fileSelected = null
      this.attachementLink = ''
      this.$refs.inputFile.value = ''
      this.$refs.inputFile.files = null
      this.initialFile = ''
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';

.attachement {
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
  overflow: hidden;
}
#custom-link {
  outline: none;
  width: 100%;
}
#custom-file {
  width: 82px;
  border-left: 1px solid #E2E2E2;
}
</style>
